import TerminalHeader from '../elements/terminal_header';
import SidebarMenu from '../elements/sidebar'
import {useParams, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { loginRequest } from "../config/authConfig";
import { useMsal} from '@azure/msal-react';
import Loader from '../elements/loader';
import Card from '../elements/card';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment'
import API from "../services/api";
import Search from "../blocks/search";
import * as Icon from 'react-bootstrap-icons';


const clientTerminal = (new API()).api;

function Bol() {
  let navigate = useNavigate();
  var [stateOnLoading, setLoading] =  useState();
  var [stateTerminal, setTerminal] = useState({name: ""});
  var [stateBol, setBol] = useState([]);
  var [stateAllBol, setAllBol] = useState([]);
  var [stateBolPDFUrl, setBolPDFUrl] = useState({link: ""});

  let {termId} = useParams();

  
  const { instance, accounts } = useMsal();

  const getBolInformation = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('Terminal/Get?termId=' + termId, {headers: {"Authorization" : "Bearer " + response.accessToken}}).then((response) => {
        setLoading(true);
        if(response.status != 200) {
          return navigate("/error");
        }
        setTerminal(response.data);  
      });

      clientTerminal.get('bol/ListByLoggedUser?PlantCode=' + termId, {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.accessToken}}).then((response) => {
        setLoading(false);
        setBol(response.data);
        setAllBol(response.data);
      });
      return true
    }).catch((e) => {
      return false
    });
  }

  function converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
        type: contentType
    }); //statement which creates the blob
    return blob;
  }

  function getPDFurl(byteFile){
    try {
      var blob = converBase64toBlob(byteFile, 'application/pdf');
      var blobURL = URL.createObjectURL(blob);
      return {status: "success", link: blobURL}
    } catch {
      return {status: "error", link: ""}
    }
    
  }

  useEffect(() => { getBolInformation(); }, [])

  const getBolFile = (id) => {

    var term_minified = termId.substr(termId.length - 4);
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    setLoading(true);
    toast('Reading data to create file...', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });

    instance.acquireTokenSilent(request).then((response) => {
      clientTerminal.get('bol/get?id=' + term_minified + '_' + id, {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.accessToken}}).then((response) => {
        setLoading(false);
        let pdfurl = getPDFurl(response.data.pdf)
          window.open(pdfurl.link, '_blank');

          toast.success('File created with success.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        
      }).catch((e) => {
        toast.error('Error reading file data.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        setLoading(false);
        return false
      });
      return true
    }).catch((e) => {
      toast.error('Error to read file data.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setLoading(false);
      return false
    });
  }

  function eBolSearch(eBols){
    var search_bol = []
    if (eBols != ""){ 
      search_bol = stateAllBol.filter(bl => bl.id.includes(eBols));
      setBol(search_bol);
    }else{
      setBol(stateAllBol);
    }
    return search_bol;
  }

  function convertDateTime(dt) {
    return moment.parseZone(dt).utc(true).format("YYYY/MM/DD hh:mm A")
  }

  return (
    <>
    <TerminalHeader name={stateTerminal.name} address={stateTerminal.addr1}/>
    <div className="container">
      <div className="em-l em-l--two-column-sticky ">
        <div className="em-l__secondary">
          <div className="fpo-block"><SidebarMenu termId={termId} />
        </div>
      </div>
      <div className="em-l__main">
      {stateOnLoading ? <Loader /> :
      <> 
        <div className="fpo-block fpo-block--dark">
          <div style={{marginTop:10, marginLeft:38, marginRight:38, position: 'sticky', top: 0, zIndex: 1000}}>
            <Search callback={eBolSearch} placeholder="BOL ID..."/>
          </div>
          <div className="container">
            <div className="row"></div>
            <div className='row'>
              <div className="em-l-grid em-l-grid--1-to-3up box-bol">
                {
                  stateBol.map(bol => {
                    return (
                    <>
                    <Card type="bol" pqm={bol.isPQM} title={bol.id} information={<>Transaction Date: {convertDateTime(bol.transactionDate)}</>} meta={getPDFurl(bol.metadata)} id={bol.ID} prepare_file_action={() => getBolFile(bol.id)}/>
                    </>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        </>
        }
        </div>
      </div>
    </div>
    <ToastContainer/>
    </>
  );
}
 
export default Bol;