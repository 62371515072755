import React, { useEffect } from "react";
import Modal from "react-modal";

function ModalElement({
  title,
  description,
  content,
  footer,
  buttonOpenModal,
  width = "80vw",
  type = "information",
  functionality,
  isOpen,
}) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "100vh",
      width: width,
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    functionality && functionality();
  }

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <span onClick={openModal} className="modal__button--circle button--blue">
        {buttonOpenModal}
      </span>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=""
        ariaHideApp={false}
      >
        <button
          onClick={closeModal}
          className="em-c-btn em-c-btn--secondary btn--right em-u-margin-top-double create-user-margin"
          id="index-button"
          style={type == "create-user" ? { right: 105 } : {}}
        >
          <span className="em-c-btn__text ">Close</span>
        </button>
        <span className="em-c-tile__headline">{title}</span>
        <br />
        <span className="em-c-tile__desc">{description}</span>
        <br />
        <div className="modal__content">{content}</div>
        <div className="em-c-modal__footer ">
          {type == "information" ? (
            <div className="em-c-btn-group  ">
              <button
                className="em-c-btn em-c-btn--primary     "
                onClick={functionality}
              >
                <span className="em-c-btn__text">Confirm</span>
              </button>
            </div>
          ) : type == "create-user" ? (
            <></>
          ) : (
            <div className="em-c-btn-group  ">
              <button
                className="em-c-btn em-c-btn--primary     "
                onClick={closeModal}
              >
                <span className="em-c-btn__text">Confirm</span>
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ModalElement;
