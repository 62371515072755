import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import { loginRequest } from "../config/authConfig";
import {
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import API from "../services/api";

const clientMobile = (new API()).api;

function Header() {

  let isDev = process.env.REACT_APP_IS_DEV == "true" ? true : false;
  
  const { instance, accounts } = useMsal();
  var [stateIsAdmin, setIsAdmin] = useState();
  var [internalAccounts, setInternalAccounts] = useState();
  var [internalAccount, setInternalAccount] = useState();
  var [showAccounts, setShowAccounts] = useState(false);

  function IsAdmin(){
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      clientMobile.get('me/IsAdmin', {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.accessToken}}).then((response) => {
        setIsAdmin(response.data);
      });
      return true
    })
  }

  function getInternalAccounts(){
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      let currentInternalAccount = document.cookie.split('; ').find((row) => row.startsWith('internalAccount='))?.split('=')[1];
      if (currentInternalAccount != "undefined") {
        setInternalAccount(currentInternalAccount);
      }
      clientMobile.get('user/internalAccounts', {headers: {"Access-Control-Allow-Origin": "*", "Authorization" : "Bearer " + response.accessToken}}).then((response) => {
        setInternalAccounts(response.data);
        if(currentInternalAccount == null) {
          setInternalAccount(response.data[0]);
          document.cookie = `internalAccount=${response.data[0]}; expires=Session; path=/`;
        }
      });
      return true;
    })
  }

  function updateInternalAccount(account) {
    setShowAccounts(false);
    if(account != internalAccount) {
      document.cookie = `internalAccount=${account}; expires=Session; path=/`;
      setInternalAccount(account);
      location.reload();
    }
  }

  useEffect(() => { getInternalAccounts(); IsAdmin(); }, [])

  function showInternalAccount() {
    if(internalAccount != undefined 
      && internalAccount != null 
      && internalAccount != "") {
      return ` - ${internalAccount} ▼`;
    }
    return "";
  }

  return (
    <>
      <header
      className={isDev ? "em-c-header em-c-header--condensed" : "em-c-header em-c-header--condensed em-c-header--blue"}
      style={isDev ? {background:"#ed8b00", backgroundImage:"linear-gradient(45deg, #ed8b00 0%, #0c69b0 100%)"} : {}}
      role="banner"
      >
  <div className="layout-header" id="header-block" >
    <div className="em-c-header__body header-body">
      <div className="em-c-header__title-container ">
        <h2 className="em-c-header__title">
          <Link to="/" rel="home" className="em-c-header__title-link">{isDev ? "Terminal FastPass Development" : "Terminal FastPass" }</Link>
        </h2>
      </div>
      <nav id="nav" aria-label="Main navigation" className="header-nav">
        <ul className="em-c-primary-nav__list" role="menubar" id="horizontalMenu">
          <li className="em-c-primary-nav__item " role="menuitem" key="menu-item">
            <Link to="/" className="em-c-primary-nav__link  em-is-current">Home</Link>
          </li>
          {
            useIsAuthenticated() ? 
            <>
            {
                stateIsAdmin ?
                <li className="em-c-primary-nav__item " role="menuitem" key="menu-item-2">
                <Link to="/admin" rel="admin" className="em-c-primary-nav__link  em-is-current">Admin</Link>
                </li>
                :
                <></>
              }
                <li className="em-c-primary-nav__item " role="menuitem" key="menu-item-3">
                <span
                  className="em-c-primary-nav__link  em-is-current em-u-font-style-light headerBtn"
                  href="#"
                  aria-expanded="false"
                  aria-current="false"
                  aria-label="Home"
                  onClick={()=> {setShowAccounts(!showAccounts)}}
                >
                  {accounts[0]?.name + showInternalAccount()}
                </span>
                <div>
                  { showAccounts ? <div className="headerDropDownHide"><div className="headerDropDown"> {internalAccounts?.map((ia) => (
                    <div className={internalAccount == ia ? 'headerDropDownItemActive' : 'headerDropDownItem'} onClick={() => updateInternalAccount(ia)} key={ia}>{ia}</div>
                  ))}</div></div> : <></>}
                </div>
              </li>
              </>
              :
            <>
              {/* <Link className="em-c-primary-nav__link  em-is-current" onClick={() => handleLogin("redirect")}>Log in</Link> */}
            </>
          }
        </ul>
      </nav>
    </div>
  </div>
</header>
      </>
    )
  };
  
  export default Header;